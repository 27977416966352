import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
// import OutsideClickHandler from "react-outside-click-handler";
import {
  getSingleUser,
  changeUserStatus,
  getGuarantorData,
  approveGuarantors,
  rejectGuarantors,
  rejectGuarantorOne,
  rejectGuarantorTwo,
  changePaymentStatus
} from "../../lib/data";
import ReactToPrint from "react-to-print";

import arrow from "../../images/svg/arrow-back.svg";
import arrow2 from "../../images/svg/arrow-down.svg";
import arrow3 from "../../images/svg/arrow-dwn-purple.svg";
import download from "../../images/svg/download.svg";
import Loader from "../Loader";
import axios from "axios";

function Details() {
  const [clicked, setClicked] = useState("bio");
  const [guarantorDetails, setGuarantorDetails] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [changeStatus, setChangeStatus] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [downloadHtml, setDownloadHtml] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get("user");
  // const userImage = `https://api.grazacacademy.com/uploads/${userDetails.means_of_identification}`;


  useEffect(() => {
    getSingleUser(user_id)
      .then((response) => {
        console.log(response)
        if (response.data.status === 200) {
          setUserDetails(response.data.data.result);
        } else {
          window.location.reload();
        }
      })
      // .then((res) => {
      //   console.log(res)
      //   if (res.data.status === 200) {
      //     console.log(res.data.result[0])
      //     setUserDetails(res.data.result[0]);
      //     console.log(res);
      //   } else {
      //     window.location.reload();
      //   }
      // })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          toast.error(err.response);
        } else {
          toast.error(
            "Something went wrong!!! Please make sure you have a good internet connection."
          );
        }
      });

    getGuarantorData(user_id)
      // .then((response) => response.data)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          setGuarantorDetails(res.data.data);
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          toast.error(err.response);
        } else {
          toast.error(
            "Something went wrong!!! Please make sure you have a good internet connection."
          );
        }
      });
    // }, [userDetails.status]);
  }, []);

  const updateStatus = (theStatus) => {
    const statusChangeFunction = () => {
      const payload = JSON.stringify({
        email: userDetails.email,
      });
      console.log(payload);
      return changeUserStatus(theStatus, payload);
    };

    const response = toast.promise(statusChangeFunction, {
      pending: `Changing user status to ${theStatus}`,
      success: "Status changed successfully.",
      error: "Unable to change user status, please try again!",
    });
    response
      .then((data) => {
        console.log(data);
        window.location.reload();
      })
      .catch((err) => console.log(err.response));
  };

  const downloadResp = (resp) => {
    setDownloadHtml(resp);
  };

  const handleRejectGuarantor1 = (id) => {
    rejectGuarantorOne(id)
    .then(res => {
      if(res.data.status === 200){
        toast.success(res.data.message)
      }
    })
    
  };
  const handleRejectGuarantor2 = (id) => {
    rejectGuarantorTwo(id)
    .then(res => {
      if(res.data.status === 200) {
        toast.success(res.data.message)
      }
    })
  }
  const handleRejectAllGuarantors = (id) => {
    rejectGuarantors(id)
    .then(res => {
      if(res.data.status === 200) {
        toast.success(res.data.message)
      }
    })
    .catch(err => {
      console.log(err)
    })

  }
  const handleApproveAllGuarantors = (id) => {
    approveGuarantors(id)
    .then(res => {
      if(res.data.status === 200) {
        toast.success(res.data.message)
      }
    })
    .catch(err => {
      console.log(err)
    })
  }
  const handleLnplStatusChange = (id) => {
    changePaymentStatus(id)
    .then(res => {
      if(res.data.status === 200) {
        toast.success(res.data.message)
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  return (
    <div>
      {/* {console.log(Object.keys(userDetails).length)} */}
      {Object.keys(userDetails).length != 0 ? (
        <div className="dashboard-content">
          <ToastContainer closeButton={false} />
          <div
            className="dashboard-content-back"
            onClick={() => window.history.back()}
          >
            <img src={arrow} alt="back" />
            <p>Back</p>
          </div>
          <div className="disp-flex dashboard-content-detail-actions">
            <div></div>
            <h3>
              {userDetails.firstName} {userDetails.lastName}
            </h3>
            <div className="disp-flex dashboard-content-actions-div">
              <ReactToPrint
                content={() => downloadHtml}
                trigger={() => <p>Download Data</p>}
              />
              {/* <img src={arrow2} alt="arrow" className="det" onClick={() => setShowDownload(true)} /> */}
              <div className="pos-rel">
                {showDownload && (
                  // <OutsideClickHandler
                  //   onOutsideClick={() => {
                  //     setShowDownload(false);
                  //   }}
                  // >
                    <div className="dashboard-content-actions-byStatus new-option">
                      <ReactToPrint
                        content={() => downloadHtml}
                        trigger={() => (
                          <div className="status">
                            <label htmlFor="under-review">Bio Data</label>
                            <img
                              src={download}
                              alt="download"
                              style={{ marginLeft: "auto" }}
                            />
                          </div>
                        )}
                      />
                      {userDetails.status != "Not Submitted" && (
                        <div>
                          <hr />
                          <ReactToPrint
                            content={() => downloadHtml}
                            trigger={() => (
                              <div className="status">
                                <label htmlFor="under-review">
                                  Educational Background
                                </label>
                                <img
                                  src={download}
                                  alt="download"
                                  style={{ marginLeft: "auto" }}
                                />
                              </div>
                            )}
                          />
                        </div>
                      )}
                      {userDetails.status === "Selected" ||
                      userDetails.status === "Admitted" ||
                      userDetails.status === "Rejected" ? (
                        <div>
                          <hr />
                          <ReactToPrint
                            content={() => downloadHtml}
                            trigger={() => (
                              <div className="status">
                                <label htmlFor="under-review">
                                  Guarantor Data
                                </label>
                                <img
                                  src={download}
                                  alt="download"
                                  style={{ marginLeft: "auto" }}
                                />
                              </div>
                            )}
                          />
                          <div style={{ height: "30px" }}></div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  // </OutsideClickHandler>
                )}
              </div>
            </div>
               {userDetails.paymentOption === "learn_now_pay_later" && (
              <div className="disp-flex dashboard-content-actions-div">
                <p className="text2" onClick={() => setChangeStatus(true)}>LNPL Status</p>
                <img
                  src={arrow3}
                  alt="arrow"
                  className="det"
                  onClick={() => setChangeStatus(true)}
                />
                <div className="pos-rel">
                  {changeStatus && (
                    // <OutsideClickHandler
                    //   onOutsideClick={() => {
                    //     setChangeStatus(false);
                    //   }}
                    // >
                      <div className="dashboard-content-actions-byStatus new-option">
                          <div>
                            <div
                              className="status"
                              onClick={() => handleLnplStatusChange(userDetails.id)}
                            >
                              <label htmlFor="reject">Reject</label>
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  id="reject"
                                  name="status"
                                  value="rejected"
                                />
                                <label htmlFor="reject"></label>
                              </div>
                            </div>
                          </div>
                      </div>
                    // </OutsideClickHandler>
                  )}
                </div>  
              </div>
            ) } 


            {/* {userDetails.status === "Rejected" ||
            userDetails.status === "Selected" ||
            userDetails.status === "Admitted" ? (
              <div className="disp-flex dashboard-content-actions-div">
                <p className="text2" onClick={() => setChangeStatus(true)}>
                  Change Status
                </p>
                <img
                  src={arrow3}
                  alt="arrow"
                  className="det"
                  onClick={() => setChangeStatus(true)}
                />
                <div className="pos-rel">
                  {changeStatus && (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setChangeStatus(false);
                      }}
                    >
                      <div className="dashboard-content-actions-byStatus new-option">
                        {userDetails.status != "Admitted" && (
                          <div>
                            <div
                              className="status"
                              onClick={() => updateStatus("Admitted")}
                            >
                              <label htmlFor="admitted">Admitted</label>
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  id="admitted"
                                  name="status"
                                  value="admitted"
                                />
                                <label htmlFor="admitted"></label>
                              </div>
                            </div>
                          </div>
                        )}
                        {userDetails.status != "Rejected" && (
                          <div>
                            {userDetails.status != "Admitted" && <hr />}
                            <div
                              className="status"
                              onClick={() => updateStatus("Rejected")}
                            >
                              <label htmlFor="rejected">Rejected</label>
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  id="rejected"
                                  name="status"
                                  value="rejected"
                                />
                                <label htmlFor="rejected"></label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )} */}
            {console.log(userDetails.paymentOption === 'learn_now_pay_later')}
            {userDetails.paymentOption === 'learn_now_pay_later' && <div className="dashboard-content-actions-guarantorBtnContainer">
              <div><button className="" onClick={() => handleApproveAllGuarantors(userDetails.id)}>Approve Guarantors</button></div>
              <div><button className="dashboard-content-actions-guarantorBtnContainer-btn" onClick={() => handleRejectAllGuarantors(userDetails.id)}>Decline Guarantors</button></div>    
            </div>}
          </div>
          <div className="dashboard-content-detail">
            <p
              className={
                clicked === "bio"
                  ? "dashboard-content-detail-p active"
                  : "dashboard-content-detail-p"
              }
              onClick={() => setClicked("bio")}
            >
              Bio Data
            </p>
            {/* {userDetails.status != "Not Submitted" && (
              <p
                className={
                  clicked === "edu"
                    ? "dashboard-content-detail-p active"
                    : "dashboard-content-detail-p"
                }
                onClick={() => setClicked("edu")}
              >
                Educational Background
              </p>
            )} */}
            {/* {userDetails.status === "Admitted" ? ( */}
            {userDetails.paymentOption === "learn_now_pay_later" ? (
              <p
                className={
                  clicked === "guarantor"
                    ? "dashboard-content-detail-p active"
                    : "dashboard-content-detail-p"
                }
                onClick={() => setClicked("guarantor")}
              >
                Guarantor Data
              </p>
            ) : (
              <></>
            )}
          </div>
          {clicked === "bio" && (
            <div
              className="dashboard-content-form"
              ref={(response) => downloadResp(response)}
            >
              <form>
                <div className="dashboard-content-form-container">
                  <h5>First Name</h5>
                  <p>{userDetails?.firstName}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Last Name</h5>
                  <p>{userDetails?.lastName}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Email Address</h5>
                  <p>{userDetails?.email}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Phone Number</h5>
                  <p>{userDetails?.phoneNumber}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Gender</h5>
                  <p>{userDetails?.gender}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Status</h5>

                  <div className="dashboard-content-table">
                    <span
                      className={
                        userDetails.status === "Admitted"
                          ? "admitted"
                          : userDetails.status === "Rejected"
                          ? "rejected"
                          : userDetails.status === "Selected"
                          ? "selected"
                          : userDetails.status === "Under Review"
                          ? "under-review"
                          : "not-submitted"
                      }
                    >
                      {userDetails.status}
                    </span>
                  </div>
                </div>
                {userDetails.status != "Not Submitted" && (
                  <div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Payment Status</h5>
                      <p>
                        {userDetails?.isPaid === null
                          ? "Not Yet Paid"
                          : "Payment made"}
                      </p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Onsite</h5>
                      <p>{userDetails?.onSite === 1 ? "Yes" : "No"}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Age Range</h5>
                      <p>{userDetails?.ageRange}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Preferred course at Grazac</h5>
                      <p>{userDetails?.preferred_course}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>State of residence</h5>
                      <p>{userDetails?.state_of_residence}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Current city and Location</h5>
                      <p>{userDetails?.current_city_and_location}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Payment Option</h5>
                      <p>{userDetails?.paymentOption}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Physically Available</h5>
                      <p>{userDetails?.physically_available}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Are you resident in Abeokuta</h5>
                      <p>{userDetails?.resident_in_abeokuta}</p>
                    </div>
                    <hr />
                    <div className="dashboard-content-form-container">
                      <h5>Why should you be selected for the cohort</h5>
                      <p>{userDetails?.why_selected_for_cohort}</p>
                    </div>
                    {/* <div className="dashboard-content-form-container">
                      <h5>Means of Identification (NIN/ Voter's Card)</h5>
                      <img
                        className="dashboard-content-form-container-image"
                        src={userImage}
                        alt={userDetails.means_of_identification}
                      />
                    </div> */}
                  </div>
                )}
              </form>
            </div>
          )}
          {/* {clicked === "edu" && (
            <div
              className="dashboard-content-form"
              ref={(response) => downloadResp(response)}
            >
              <form>
                <div className="dashboard-content-form-container">
                  <h5>Course of Study</h5>
                  <p>{userDetails?.course_of_study}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Grade Achieved</h5>
                  <p>{userDetails?.grade_achieved}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Qualifications</h5>
                  <p>{userDetails?.qualifications}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Programming / Design Experience</h5>
                  <p>{userDetails?.programming_skills}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>NYSC Status</h5>
                  <p>{userDetails?.nysc_status}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>
                    Why do you think you should be selected as a part of this
                    cohort?
                  </h5>
                  <p>{userDetails?.why_selected_for_cohort}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>
                    Will you be physically available for the next 6 months?
                  </h5>
                  <p>{userDetails?.will_you_pay_legal_fees}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>
                    If selected, will you be able to pay the legal fees of
                    #30,000?
                  </h5>
                  <p>{userDetails?.physically_available}</p>
                </div>
              </form>
            </div>
          )} */}
          {clicked === "guarantor" && (
            <div
              className="dashboard-content-form"
              ref={(response) => downloadResp(response)}
            >
              {/* <form className="guarantor1"> */}
              <div className="guarantor1">
                <h5 style={{ marginBottom: "24px" }}>Guarantor Details 1</h5>
                <div className="dashboard-content-form-container">
                  <h5>First Name</h5>
                  <p>{guarantorDetails[0]?.first_name}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Last Name</h5>
                  <p>{guarantorDetails[0]?.last_name}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Email Address</h5>
                  <p>{guarantorDetails[0]?.email_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Phone Number</h5>
                  <p>{guarantorDetails[0]?.phone_number}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Gender</h5>
                  <p>{guarantorDetails[0]?.gender}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Office Address</h5>
                  <p>{guarantorDetails[0]?.office_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Home Address</h5>
                  <p>{guarantorDetails[0]?.home_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Profession</h5>
                  <p>{guarantorDetails[0]?.profession}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Name of Organization</h5>
                  <p>{guarantorDetails[0]?.organization_name}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Office Address</h5>
                  <p>{guarantorDetails[0]?.office_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Email Address of Employer</h5>
                  <p>{guarantorDetails[0]?.employer_email_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Phone Number of Employer</h5>
                  <p>{guarantorDetails[0]?.employer_phone_number}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Relationship with guarantor</h5>
                  <p>{guarantorDetails[0]?.relationship}</p>
                </div>
                <button className="" onClick={() => handleRejectGuarantor1(userDetails.id)}>
                  Reject Guarantor 1
                </button>
              </div>
              {/* <form className="guarantor2"> */}
              <div className="guarantor2">
                {/* <h5 style={{ marginTop: "50px" }}>Guarantor Details 2</h5> */}
                <h5 style={{ marginBottom: "24px" }}>Guarantor Details 2</h5>
                <div className="dashboard-content-form-container">
                  <h5>First Name</h5>
                  <p>{guarantorDetails[1]?.first_name}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Last Name</h5>
                  <p>{guarantorDetails[1]?.last_name}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Email Address</h5>
                  <p>{guarantorDetails[1]?.email_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Phone Number</h5>
                  <p>{guarantorDetails[1]?.phone_number}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Gender</h5>
                  <p>{guarantorDetails[1]?.gender}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Office Address</h5>
                  <p>{guarantorDetails[1]?.office_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Home Address</h5>
                  <p>{guarantorDetails[1]?.home_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Profession</h5>
                  <p>{guarantorDetails[1]?.profession}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Name of Organization</h5>
                  <p>{guarantorDetails[1]?.organization_name}</p>
                </div>
                <hr />
                {/* <div className="dashboard-content-form-container">
                  <h5>Relationship with guarantor</h5>
                  <p>{guarantorDetails[1]?.name_of_employer}</p>
                </div> */}
                {/* <hr /> */}
                <div className="dashboard-content-form-container">
                  <h5>Email Address of Employer</h5>
                  <p>{guarantorDetails[1]?.employer_email_address}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Phone Number of Employer</h5>
                  <p>{guarantorDetails[1]?.employer_phone_number}</p>
                </div>
                <hr />
                <div className="dashboard-content-form-container">
                  <h5>Relationship with guarantor</h5>
                  <p>{guarantorDetails[1]?.relationship}</p>
                </div>
                <button onClick={() => handleRejectGuarantor2(userDetails.id)}>
                  Reject Guarantor 2
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
        //    <p>loading ooh</p>
      )}
    </div>
  );
}

export default Details;
