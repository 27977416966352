import { Modal } from "react-bootstrap";
import {useState} from "react";
import { DefaultEditor } from 'react-simple-wysiwyg';
import { sendSelectedMails } from "../../lib/data";
import { ToastContainer, toast } from 'react-toastify';

import loader from "../../images/gif/loader.gif";


function Popup({show, formAction, handleClose, emails}) {
    const [html, setHtml] = useState('');
    const [subject, setSubject] = useState("");
    
    function onChange(e) {
      setHtml(e.target.value);
    }

    const sendMail = (e) => {
      e.preventDefault()
      var btn = document.getElementById("actionBtn");
      btn.innerHTML=`<img src=${loader} width="20px" />`;

      let payload = JSON.stringify({
          emails: emails,
          // subject: subject,
          // BODY: html
      });
      console.log(payload)

      sendSelectedMails(formAction, payload)
            .then(response => response.data)
            .then(resp=>{
                console.log(resp)
                if(resp.status===201){
                  toast.success('Mail sent to all selected applicants!');
                  btn.innerHTML=`Send Email`;
                  setTimeout( function (){
                    handleClose()
                    window.location.reload();
                    }, 5000);
                } else{
                  toast.error('Mail not sent. Please try again!');
                }
            })
            .catch(err=>{
                console.log(err);
                toast.error('Mail not sent. Please try again!')
                btn.innerHTML=`Send Email`;
            })
    }

    return (
      <>
        <ToastContainer closeButton={false} />
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{textTransform:"capitalize"}}>{formAction} All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="dashboard-content-detail_form" onSubmit={sendMail}>
              {/* <label>Subject</label>
              <input type="text" onChange={(e)=>setSubject(e.target.value)} />
              <label>Body</label><br/>
              <div>
                <DefaultEditor value={html} onChange={onChange} />
              </div> */}
              {/* <textarea rows="7" style={{width:"100%"}} onChange={onChange} /> */}
              <button type="submit" id="actionBtn">Send Email</button>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
}

  export default Popup;